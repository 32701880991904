var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"aQe2FVcm6ij5O-niIlYvv"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { appConfig } from '@app/config';
import { SENTRY_ERRORS_TO_SKIP } from '@app/constants/sentryErrorsToSkip';
import * as Sentry from '@sentry/nextjs';

if (appConfig.env === 'production') {
  Sentry.init({
    dsn: appConfig.sentryDsn,
    ignoreErrors: SENTRY_ERRORS_TO_SKIP,
    sampleRate: 0.6,
    debug: false,
    replaysOnErrorSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    integrations: [
      Sentry.replayIntegration({
        // Additional Replay configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });
}
