export const SENTRY_ERRORS_TO_SKIP = [
  /sentry/i,
  /walletconnect/i,
  /IDBDatabase/i,
  /(reading 'timestamp')/i,
  /No matching key/i,
  /cannot serialize cyclic/i,
  /Access is denied/i,
  /evaluating 't.timestamp'/i,
  /circular structure to JSON/i,
];
